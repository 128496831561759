import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledDiv = styled.div`
  text-align: center;
  @media (max-width: 750px) {
    text-align: left;
    padding-left: 1em;
  }
`
const StyledLink = styled(Link)`
  background-color: white;
  color: inherit;
  text-decoration: none;
  padding: 0.5em 0;
  border: solid 1px transparent;
  transition: border-color 1s linear;
  &:hover {
    border-color: #2a3546;
  }
  @media (max-width: 750px) {
    padding: 0;
  }
`
const StyledName = styled.span`
  display: block;
  margin: 1em auto 0 auto;
  @media (max-width: 750px) {
    display: inline-block;
    vertical-align: bottom;
    padding-left: 1em;
  }
`
const StyledImg = styled.img`
  display: block;
  width: 90%;
  margin: auto;
  @media (max-width: 750px) {
    display: inline-block;
    vertical-align: middle;
    width: 4em;
  }
`
const FeatureCard = ({ title, illustration, slug }) => {
  return (
    <StyledLink to={"/stories/" + slug}>
      <StyledDiv>
        <StyledImg
          src={require(`../../images/stories/${illustration}`)}
          alt={title}
        />
        <StyledName>{title}</StyledName>
      </StyledDiv>
    </StyledLink>
  )
}

export default FeatureCard
