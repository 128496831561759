import React from "react"
import styled from "styled-components"
import FeatureCard from "./FeatureCard"
import stories from "../../json/stories.json"
import SurveyLink from "../storypage/SurveyLink"
const StyledContainer = styled.div`
  margin-bottom: 2em;
`
const StyledDiv = styled.div`
  width: 80%;
  margin: 2em auto;
  @media (max-width: 750px) {
    width: 95%;
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5em;
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`

const Featured = () => {
	//console.log(stories)
  var arr = [25, 26, 27, 28]

  const featured_stories = arr.map((e, k) => (
    <FeatureCard
      key={k}
      slug={stories[e].slug}
      illustration={stories[e].cardpic}
      title={stories[e].fullname}
    />
  ))
  return (
    <StyledContainer>
      <StyledDiv>
        <StyledGrid>{featured_stories}</StyledGrid>
      </StyledDiv>
      <SurveyLink />
    </StyledContainer>
  )
}
export default Featured
