import React from "react"
import styled from "styled-components"
const StyledContainer = styled.div`
  width: 30em;
  margin: 1em auto;
  text-align: center;
  background-color: #f1c761;
  color: #2a3546;
  padding: 0.5em;
  border: solid 1px transparent;
  transition: border-color 1s linear;
  &:hover {
    border-color: black;
  }
  @media (max-width: 750px) {
    width: 90%;
  }
`
const StyledAnchor = styled.a`
  color: inherit;
  text-decoration: none;
  font-weight: bold;
`
const SurveyLink = () => {
  return (
    <StyledContainer>
      <StyledAnchor
        href="https://www.surveymonkey.com/r/5Q552YR"
        target="_blank"
        rel="noopener"
      >
        Tell us what you think about Muslimah Stories
      </StyledAnchor>
    </StyledContainer>
  )
}

export default SurveyLink
