import React from "react"
import styled from "styled-components"
import collage from "../../images/collage-01.png"
const StyledGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  background-size:contain;
  background-repeat:repeat-x;
  background-image: url(${collage});
  @media(max-width:750px){
	  
	  height: 300px;
  }`
const StyledDiv = styled.h2`
  text-align: center;
  background-color: #f1c761;
  color: #2a3546;
  font-size: 1.3em;
  padding: 1em;
  border-style: double;
`
// background-image: linear-gradient(to left bottom, #b76464, #833164, #527e7d);
const Intro = () => (
  <StyledGrid>
    <StyledDiv className="rancho">
      Ordinary Women on Extraordinary Journeys
    </StyledDiv>
  </StyledGrid>
)

export default Intro
