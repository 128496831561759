import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/homepage/Intro"
import Featured from "../components/homepage/Featured"
import About from "../components/homepage/About"
import styled from "styled-components"

const StyledMain = styled.main`
  background-color: #b76464;
  padding-bottom: 1em;
`
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <StyledMain>
      <Intro />
      <Featured />
      <About />
    </StyledMain>
  </Layout>
)

export default IndexPage
