import React from "react"
import styled from "styled-components"
import projectStatement from "../../json/projectstatement.json"
import funders from "../../images/funders.png"
const StyledDiv = styled.div`
  background-color: white;
  padding: 1em;
  width: 80%;
  margin: 1em auto;
  p {
    margin: 1em 0;
  }
  h2 {
    font-family: Pompiere;
  }
  h3 {
    font-family: Pompiere;
    text-align: center;
  }
  @media (max-width: 750px) {
    width: 95%;
  }
`
const StyledImg = styled.img`
  width: 100%;
  margin-top: 1em;
`
const About = () => {
  return (
    <StyledDiv>
      <div dangerouslySetInnerHTML={{ __html: projectStatement.text }} />
      <StyledImg src={funders} alt="Logos of our funders" />
    </StyledDiv>
  )
}
export default About
